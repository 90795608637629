import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { MATCHING_CONFIG_STATE_ERROR } from './auth-error.model';

@Injectable({ providedIn: 'root' })
export class CheckAuthGuard {
  constructor(private readonly oidcSecurityService: OidcSecurityService) {}

  canActivate(): Observable<boolean> {
    return this.oidcSecurityService.checkAuth().pipe(
      switchMap(() => of(true)),
      catchError((error) => {
        const stringError = JSON.stringify(error, Object.getOwnPropertyNames(error));
        const isUndefinedState = stringError.includes(MATCHING_CONFIG_STATE_ERROR);

        if (isUndefinedState) {
          return of(false);
        }

        return throwError(error);
      }),
    );
  }

  canActivateChild() {
    return this.canActivate();
  }
}
