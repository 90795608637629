import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class MenuService {
  updateSubject = new Subject();
  updateEvent = this.updateSubject.asObservable();
  loadingSubject = new Subject();
  loadingEvent = this.loadingSubject.asObservable();
  updateSelectedAppSubject = new Subject<Record<string, any> | void>();
  updateSelectedAppEvent = this.updateSelectedAppSubject.asObservable();
  private displaySubject = new BehaviorSubject(true);
  public toggleEvent = this.displaySubject.asObservable();

  show() {
    this.displaySubject.next(true);
  }

  hide() {
    this.displaySubject.next(false);
  }
}
