import { Injectable } from '@angular/core';
import { flatMap } from 'lodash-es';
import { BehaviorSubject, Observable, forkJoin, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { DashpivotEvent, EventNotifierService, EventTypes } from '@site-mate/dashpivot-shared-library';

import { FolderSelectionService } from 'app/folders/folder-selection.service';
import { FolderNavBarService } from 'app/layout/folder-navbar/folder-navbar.service';
import { OverlayService } from 'app/secure/overlay.service';
import { SegmentService } from 'app/segment/segment.service';
import { DeploymentModes } from 'app/shared/model/lists/deployment-modes.model';
import { ProjectWeb } from 'app/shared/model/project.model';
import { TeamWeb } from 'app/shared/model/team.model';
import { TemplateWeb } from 'app/shared/model/template.model';
import { ToastrService } from 'app/shared/service/toastr.service';

import { TemplatesService } from './templates.service';

@Injectable({
  providedIn: 'root',
})
export class TemplateDeploymentService {
  private readonly templatesBeingDeployedSubject = new BehaviorSubject<TemplateWeb[] | null>(null);
  templatesBeingDeployed$ = this.templatesBeingDeployedSubject.asObservable();

  private readonly activeDeployModeSubject = new BehaviorSubject<DeploymentModes>(null);
  activeDeployMode$ = this.activeDeployModeSubject.asObservable();

  private teamsAlreadyDeployed: TeamWeb[] = [];

  private selectedTeamIds: string[] = [];

  constructor(
    private readonly templatesService: TemplatesService,
    private readonly folderSelectionService: FolderSelectionService,
    private readonly overlayService: OverlayService,
    private readonly folderNavbarService: FolderNavBarService,
    private readonly toastrService: ToastrService,
    private readonly segmentService: SegmentService,
  ) {
    this.subscribeToOverlayCliked();
    this.subscribeToSelectedFolders();
  }

  private subscribeToSelectedFolders() {
    this.folderSelectionService.selectedFolders$.subscribe((selectedFolders) => {
      this.selectedTeamIds = [...selectedFolders.values()]
        .filter(({ kind }) => kind === 'team')
        .map(({ _id }) => _id);
    });
  }

  private subscribeToOverlayCliked() {
    this.overlayService.overlayClicked$.subscribe(() => {
      this.stopDeployMode();
    });
  }

  startDeployMode(templates: TemplateWeb[]) {
    if (templates.length === 1) {
      void this.startSingleTemplateDeployment(templates[0]);
    } else {
      this.startBulkTemplateDeployment(templates);
    }
  }

  private async startSingleTemplateDeployment(template: TemplateWeb) {
    const [{ teamIds }, companyTree] = await Promise.all([
      this.templatesService.getDeploymentStatus(template._id).toPromise(),
      this.folderNavbarService.getCompanyTree(template.parents[0]).toPromise(),
    ]);

    this.teamsAlreadyDeployed = flatMap(companyTree, (project: ProjectWeb) => project.teams).filter((team) =>
      teamIds.some((id: string) => team._id === id),
    );

    this.folderSelectionService.deselectAllFolders();
    this.folderSelectionService.selectFolders(this.teamsAlreadyDeployed);
    this.templatesBeingDeployedSubject.next([template]);
    this.activeDeployModeSubject.next(DeploymentModes.Management);
    this.overlayService.showOverlay();
  }

  private startBulkTemplateDeployment(templates: TemplateWeb[]) {
    this.folderSelectionService.deselectAllFolders();
    this.templatesBeingDeployedSubject.next(templates);
    this.activeDeployModeSubject.next(DeploymentModes.BulkDeploy);
    this.overlayService.showOverlay();
  }

  startUndeployMode(templates: TemplateWeb[]) {
    this.folderSelectionService.deselectAllFolders();
    this.templatesBeingDeployedSubject.next(templates);
    this.activeDeployModeSubject.next(DeploymentModes.BulkUndeploy);
    this.overlayService.showOverlay();
  }

  stopDeployMode() {
    this.overlayService.hideOverlay();
    this.folderSelectionService.deselectAllFolders();
    this.templatesBeingDeployedSubject.next(null);
    this.activeDeployModeSubject.next(null);
  }

  applyDeployedChanges() {
    const deployFromMode = {
      [DeploymentModes.Management]: this.manageSingleTemplate,
      [DeploymentModes.BulkDeploy]: this.bulkDeploy,
      [DeploymentModes.BulkUndeploy]: this.bulkUndeploy,
    };

    deployFromMode[this.activeDeployModeSubject.value].call(this);
  }

  private manageSingleTemplate() {
    const deployOperations = this.getDeployOperationsSingle();
    const undeployOperations = this.getUndeployOperationsSingle();

    const operations = [...deployOperations, ...undeployOperations];

    if (!this.validateOperations(operations)) {
      return;
    }

    this.getOperations(operations)
      .pipe(catchError(() => this.handleToastError('templateDeployManagementFailed')))
      .subscribe(() => {
        this.toastrService.successByKey('templateDeployManagementSuccessful');

        if (deployOperations.length) {
          this.notifyEvent(EventTypes.TemplatesDeployed, DeploymentModes.Single);
        }

        if (undeployOperations.length) {
          this.notifyEvent(EventTypes.TemplatesUndeployed, DeploymentModes.Single);
        }
      });
  }

  private bulkDeploy() {
    const operations = this.getDeployOperationsBulk();

    if (!this.validateOperations(operations)) {
      return;
    }

    this.getOperations(operations)
      .pipe(catchError(() => this.handleToastError('templatesBulkDeployFailed')))
      .subscribe(() => {
        this.toastrService.successByKey('templatesBulkDeploySuccessful');
        this.notifyEvent(EventTypes.TemplatesDeployed, DeploymentModes.Bulk);
      });
  }

  private getDeployOperationsBulk() {
    const teamsToDeployTo = this.selectedTeamIds;

    return this.getDeployOperationsForTeams(teamsToDeployTo);
  }

  private getDeployOperationsSingle() {
    const teamsToDeployTo = [];

    this.selectedTeamIds.forEach((selectedFolderId) => {
      const wasDeployedBefore = this.teamsAlreadyDeployed.some(({ _id }) => _id === selectedFolderId);
      if (!wasDeployedBefore) {
        teamsToDeployTo.push(selectedFolderId);
      }
    });

    return this.getDeployOperationsForTeams(teamsToDeployTo);
  }

  private getDeployOperationsForTeams(teamIds: string[]) {
    const operations = [];

    if (teamIds.length !== 0) {
      this.templatesBeingDeployedSubject.value.forEach((template) => {
        operations.push(this.templatesService.push(template._id, teamIds));
      });
    }

    return operations;
  }

  private bulkUndeploy() {
    const operations = this.getUndeployOperationsBulk();

    if (!this.validateOperations(operations)) {
      return;
    }

    this.getOperations(operations)
      .pipe(catchError(() => this.handleToastError('templatesBulkUndeployFailed')))
      .subscribe(() => {
        this.toastrService.successByKey('templatesBulkUndeploySuccessful');
        this.notifyEvent(EventTypes.TemplatesUndeployed, DeploymentModes.Bulk);
      });
  }

  private notifyEvent(event: EventTypes, Context: DeploymentModes.Single | DeploymentModes.Bulk) {
    void EventNotifierService.notify(new DashpivotEvent(event, { Context }), this.segmentService);
  }

  private getUndeployOperationsBulk() {
    const teamsToUndeployFrom = this.selectedTeamIds;

    return this.getUndeployOperationsForTeams(teamsToUndeployFrom);
  }

  private getUndeployOperationsSingle() {
    const teamsToUndeployFrom = [];

    this.teamsAlreadyDeployed.forEach((team) => {
      const isSelected = this.selectedTeamIds.some((selectedFolderId) => team._id === selectedFolderId);
      if (!isSelected) {
        teamsToUndeployFrom.push(team._id);
      }
    });

    return this.getUndeployOperationsForTeams(teamsToUndeployFrom);
  }

  private getUndeployOperationsForTeams(teamIds: string[]) {
    const operations = [];

    if (teamIds.length !== 0) {
      this.templatesBeingDeployedSubject.value.forEach((template) => {
        operations.push(this.templatesService.undeploy(template._id, teamIds));
      });
    }

    return operations;
  }

  private getOperations(operations: Array<Observable<any>>) {
    return forkJoin(operations).pipe(
      finalize(() => {
        this.folderSelectionService.deselectAllFolders();
        this.stopDeployMode();
      }),
    );
  }

  private validateOperations(operations: Array<Observable<any>>) {
    if (operations.length === 0) {
      this.folderSelectionService.deselectAllFolders();
      this.stopDeployMode();
      return false;
    }
    return true;
  }

  private handleToastError(error: string): Observable<never> {
    this.toastrService.errorByKey(error);
    return throwError(() => new Error(error));
  }
}
