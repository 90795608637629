import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

import { DashpivotEvent } from '@site-mate/dashpivot-shared-library';

import { FormService } from 'app/form/form.service';
import { PercentageBasedDownloaderData } from 'app/shared/model/downloader/percentage-based-downloader-data.model';
import { DownloaderService } from 'app/shared/service/downloader.service';
import { TimingMarkerService } from 'app/shared/service/timing-marker.service';

@Component({
  selector: 'cc-download-forms',
  templateUrl: './download-forms.component.html',
})
export class DownloadFormsComponent implements OnInit, OnDestroy {
  data: PercentageBasedDownloaderData;
  private unSubscribe = new Subject<void>();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly formService: FormService,
    private readonly downloaderService: DownloaderService,
    private readonly timingMarkerService: TimingMarkerService,
  ) {}

  ngOnInit(): void {
    const templateId = this.route.snapshot.paramMap.get('templateId');
    const timingMarkerOpts = this.getTimingMarkerOpts(templateId);
    const timingMarker = this.timingMarkerService.start(
      new DashpivotEvent(timingMarkerOpts.label, timingMarkerOpts.params),
    );

    this.route.queryParams
      .pipe(
        takeUntil(this.unSubscribe),
        map((params) => this.downloaderService.processDownloaderFormsParams(params)),
        switchMap((downloadParams) => this.formService.downloadAsPDF(templateId, downloadParams)),
      )
      .subscribe(
        (res) => {
          this.data = res;
          this.timingMarkerService.finish(timingMarker);
          this.downloaderService.cleanDownloaderLocalStorage();
        },
        (error) => {
          this.data = error;
          this.timingMarkerService.finishWithFailure(timingMarker, error);
        },
      );
  }

  private getTimingMarkerOpts(templateId) {
    const { hierarchy, kind } = this.route.snapshot.data;
    const originalImages = this.route.snapshot.queryParams?.includeOriginalImages === 'true';

    if (kind === 'single') {
      const formId = this.route.snapshot.paramMap.get('formId');
      return this.downloaderService.getDownloadSingleFormTimingMarkers(formId);
    }

    return this.downloaderService.getDownloadFormsOnlyTimingMarkers(hierarchy, templateId, originalImages);
  }

  ngOnDestroy(): void {
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }
}
