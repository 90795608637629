import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, map, switchMap, takeUntil, tap } from 'rxjs';

import { TemplatesService } from 'app/apps/templates.service';
import { PercentageBasedDownloaderData } from 'app/shared/model/downloader/percentage-based-downloader-data.model';
import { DownloaderService } from 'app/shared/service/downloader.service';

@Component({
  selector: 'cc-download-templates-csv',
  templateUrl: './download-templates-csv.component.html',
})
export class DownloadTemplatesCSVComponent {
  data: PercentageBasedDownloaderData;

  private unSubscribe = new Subject<void>();
  constructor(
    private readonly route: ActivatedRoute,
    private readonly downloaderService: DownloaderService,
    private readonly templatesService: TemplatesService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams
      .pipe(
        takeUntil(this.unSubscribe),
        map((params) => this.downloaderService.processDownloaderItemsParams(params)),
        tap((params) => {
          if (params.ids) {
            params.ids = JSON.parse(params.ids);
          }
        }),
        switchMap((downloadParams) => this.templatesService.downloadAsCSV(downloadParams)),
      )
      .subscribe({
        next: (data) => {
          this.data = data;
          this.downloaderService.cleanDownloaderLocalStorage();
        },
        error: (error) => {
          this.data = error;
        },
      });
  }

  ngOnDestroy(): void {
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }
}
