import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ExportOutputFormats } from '@site-mate/dashpivot-shared-library';

import { AuthGuard } from 'app/auth/auth-guard.service';
import { CheckAuthGuard } from 'app/auth/check-auth-guard.service';
import { DownloadListCSVComponent } from 'app/downloader/lists/csv/download-list-csv.component';

import { ExpiredFileComponent } from './expired-file/expired-file.component';
import { DownloadCSVComponent } from './forms/csv/download-csv.component';
import { DownloadFormsComponent } from './forms/download/download-forms.component';
import { ExportFormsComponent } from './forms/export/export-forms.component';
import { DownloadRegisterOnlyComponent } from './forms/register-only/download-register-only.component';
import { DownloadPhotosComponent } from './photos/download/download-photos.component';
import { DownloadPdfPhotosComponent } from './photos/download-pdf/download-pdf-photos.component';
import { QrCodePosterDownloaderComponent } from './qr-code-poster-downloader/qr-code-poster-downloader.component';
import { DownloadTemplatesCSVComponent } from './templates/csv/download-templates-csv.component';

const routes: Routes = [
  {
    // This value must match the dashpivotServicesExportsBasePrefix var from cdk/cdk.json
    path: 'exports/generated',
    pathMatch: 'prefix',
    component: ExpiredFileComponent,
    children: [
      {
        path: '**',
        component: ExpiredFileComponent,
      },
    ],
  },
  {
    path: 'downloader',
    canActivate: [CheckAuthGuard],
    children: [
      {
        path: 'apps/:templateId',
        canActivateChild: [AuthGuard],
        children: [
          {
            path: 'team/export',
            pathMatch: 'prefix',
            component: ExportFormsComponent,
            data: { hierarchy: 'team' },
          },
          {
            path: 'team/download',
            pathMatch: 'prefix',
            component: DownloadFormsComponent,
            data: { hierarchy: 'team' },
          },
          {
            path: 'organisation/export',
            pathMatch: 'prefix',
            component: ExportFormsComponent,
            data: { hierarchy: 'organisation' },
          },
          {
            path: 'organisation/download',
            pathMatch: 'prefix',
            component: DownloadFormsComponent,
            data: { hierarchy: 'organisation' },
          },
          {
            path: 'form/:formId/download',
            pathMatch: 'prefix',
            component: DownloadFormsComponent,
            data: { kind: 'single' },
          },
          {
            path: 'organisation/register-only',
            pathMatch: 'prefix',
            component: DownloadRegisterOnlyComponent,
            data: { hierarchy: 'organisation' },
          },
          {
            path: 'team/register-only',
            pathMatch: 'prefix',
            component: DownloadRegisterOnlyComponent,
            data: { hierarchy: 'team' },
          },
          {
            path: 'organisation/export-csv',
            pathMatch: 'prefix',
            component: DownloadCSVComponent,
            data: { hierarchy: 'organisation' },
          },
          {
            path: 'team/export-csv',
            pathMatch: 'prefix',
            component: DownloadCSVComponent,
            data: { hierarchy: 'team' },
          },
        ],
      },
      {
        path: 'teams/:teamId',
        canActivateChild: [AuthGuard],
        children: [
          {
            path: 'qr-code-poster/download',
            pathMatch: 'prefix',
            component: QrCodePosterDownloaderComponent,
          },
          {
            path: 'photos/download',
            pathMatch: 'prefix',
            component: DownloadPdfPhotosComponent,
          },
          {
            path: 'photos/download/single',
            pathMatch: 'prefix',
            component: DownloadPdfPhotosComponent,
            data: { kind: 'single' },
          },
          {
            path: 'photos/download/zip',
            pathMatch: 'prefix',
            component: DownloadPhotosComponent,
          },
          {
            path: 'photos/download/original',
            pathMatch: 'prefix',
            component: DownloadPhotosComponent,
            data: { kind: ExportOutputFormats.Original },
          },
        ],
      },
      {
        path: 'lists/:listId',
        canActivateChild: [AuthGuard],
        children: [
          {
            path: 'export-csv',
            pathMatch: 'prefix',
            component: DownloadListCSVComponent,
          },
        ],
      },
      {
        path: 'apps',
        canActivateChild: [AuthGuard],
        children: [
          {
            path: 'organisation/csv',
            pathMatch: 'prefix',
            component: DownloadTemplatesCSVComponent,
            data: { hierarchy: 'organisation' },
          },
          {
            path: 'team/csv',
            pathMatch: 'prefix',
            component: DownloadTemplatesCSVComponent,
            data: { hierarchy: 'team' },
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DownloaderRoutingModule {}
