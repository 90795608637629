import { Component, Input, SimpleChanges, OnChanges, OnInit, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { startCase } from 'lodash-es';
import moment from 'moment-timezone';
import { interval, of, throwError } from 'rxjs';
import { first, mergeMap, switchMap, tap } from 'rxjs/operators';

import { ExportExpirationDays, IErrorForm } from '@site-mate/dashpivot-shared-library';

import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { WINDOW } from 'app/shared/factory/window.factory';
import { DownloaderProgressStatus } from 'app/shared/model/downloader/downloader-progress-status.model';
import { PercentageBasedDownloaderData } from 'app/shared/model/downloader/percentage-based-downloader-data.model';
import { DownloaderService } from 'app/shared/service/downloader.service';
import { ErrorHandler } from 'app/shared/service/error-handler.service';

@Component({
  selector: 'cc-percentage-based-downloader',
  templateUrl: './percentage-based-downloader.component.html',
  styleUrls: [
    '../../../styles/components/abstract-downloader.scss',
    './percentage-based-downloader.component.scss',
  ],
})
export class PercentageBasedDownloaderComponent implements OnInit, OnChanges {
  @Input() data: PercentageBasedDownloaderData;
  @Input() status: DownloaderProgressStatus = DownloaderProgressStatus.Rendering;
  downloadProgressStatus = DownloaderProgressStatus;
  downloadUrl: string;
  errorMessage: string;
  pollInterval = 1500;
  progress = 0;
  progressValues = {
    initial: 0,
    half: 50,
    maxAllowed: 90,
    finished: 100,
  };

  expirationDate: string;

  errorForms: IErrorForm[] = [];

  constructor(
    private readonly titleService: Title,
    private readonly downloaderService: DownloaderService,
    private readonly errorHandler: ErrorHandler,
    @Inject(WINDOW) private readonly window: Window,
    private readonly i18nService: TmpI18NService,
    private readonly router: Router,
  ) {}

  ngOnInit() {
    this.updateProgressTitle();
    this.setExpirationDate();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.data && changes.data && this.data.error) {
      const { error }: any = this.data;
      this.updateDownloadingError(error);
      return;
    }

    if (this.data && changes.data) {
      this.startPolling();
    }
  }

  gotoForm(templateId: string, formId: string) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/', 'apps', templateId, 'forms', formId]),
    );
    this.window.open(url, '_blank');
  }

  setExpirationDate() {
    const today = new Date();
    const expirationDate = moment(today).add(ExportExpirationDays, 'days');
    this.expirationDate = expirationDate.format('MMM DD, YYYY');
  }

  private startPolling() {
    interval(this.pollInterval)
      .pipe(
        mergeMap(() => this.downloaderService.getExportSession(this.data.id)),
        switchMap((data: PercentageBasedDownloaderData) =>
          data.error ? throwError({ message: data.error }) : of(data),
        ),
        tap((data) => this.updateStatus(data)),
        first(() => this.isDownloadFinished()),
      )
      .subscribe(() => this.updateFinishedDownload(), this.updateDownloadingError.bind(this));
  }

  private updateStatus(data: PercentageBasedDownloaderData) {
    this.data = data;
    this.progress = this.calculateProgress();
    this.updateProgressTitle();

    if (!this.isDownloadFinished()) {
      this.status = this.data.status;
    }
  }

  private calculateProgress() {
    return Math.round((this.data.processedObjects * this.progressValues.maxAllowed) / this.data.totalObjects);
  }

  private updateFinishedDownload() {
    this.progress = this.progressValues.finished;
    this.updateProgressTitle();
    this.updateErrorForms();
    this.window.setTimeout(() => {
      this.downloadUrl = this.data.result;
      this.status = this.data.status;
      this.titleService.setTitle('Ready to download');
    }, 500);
  }

  private isDownloadFinished() {
    return this.data.status === DownloaderProgressStatus.Finished;
  }

  private updateProgressTitle() {
    this.titleService.setTitle(`${this.progress}% - ${startCase(this.status)}...`);
  }

  private updateErrorForms() {
    this.errorForms = this.data.errorForms || this.errorForms;
  }

  private updateDownloadingError(error) {
    this.errorHandler.handleForDebug(error, {
      data: this.data,
    });
    this.errorMessage = error?.message || this.i18nService.getMessage('downloadingError');
    this.status = DownloaderProgressStatus.Error;
    this.titleService.setTitle('Processing failed');
  }
}
