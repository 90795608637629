import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { startCase } from 'lodash-es';

import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { DownloaderProgressStatus } from 'app/shared/model/downloader/downloader-progress-status.model';
import { StateBasedDownloaderData } from 'app/shared/model/downloader/state-based-downloader-data.model';

@Component({
  selector: 'cc-state-based-downloader',
  templateUrl: './state-based-downloader.component.html',
  styleUrls: [
    '../../../styles/components/abstract-downloader.scss',
    './state-based-downloader.component.scss',
  ],
})
export class StateBasedDownloaderComponent implements OnInit, OnChanges {
  @Input() data: StateBasedDownloaderData;
  readonly downloadProgressStatus = { ...DownloaderProgressStatus };

  constructor(
    private readonly titleService: Title,
    private readonly i18nService: TmpI18NService,
  ) {}

  ngOnInit(): void {
    this.updateProgressTitle();
  }

  ngOnChanges(): void {
    this.updateProgressTitle();
  }

  private updateProgressTitle(): void {
    switch (this.data.status) {
      case DownloaderProgressStatus.Finished:
        this.titleService.setTitle(this.i18nService.getMessage('downloadingTitleFinished'));
        break;
      case DownloaderProgressStatus.Error:
        this.titleService.setTitle(this.i18nService.getMessage('downloadingTitleError'));
        break;
      default:
        this.titleService.setTitle(`${startCase(this.data.status)}...`);
    }
  }
}
