import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

import { DashpivotEvent, EventTypes } from '@site-mate/dashpivot-shared-library';

import { FormService } from 'app/form/form.service';
import { PercentageBasedDownloaderData } from 'app/shared/model/downloader/percentage-based-downloader-data.model';
import { DownloaderService } from 'app/shared/service/downloader.service';
import { TimingMarkerService } from 'app/shared/service/timing-marker.service';

@Component({
  selector: 'cc-download-csv',
  templateUrl: './download-csv.component.html',
})
export class DownloadCSVComponent implements OnInit, OnDestroy {
  data: PercentageBasedDownloaderData;

  private unSubscribe = new Subject<void>();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly formService: FormService,
    private readonly downloaderService: DownloaderService,
    private readonly timingMarkerService: TimingMarkerService,
  ) {}

  ngOnInit(): void {
    const templateId = this.route.snapshot.paramMap.get('templateId');
    const { hierarchy } = this.route.snapshot.data;
    const context = this.getCsvExportContext(this.route.snapshot.queryParams);
    const timingMarker = this.timingMarkerService.start(
      new DashpivotEvent(EventTypes.FormsRegisterActionApplied, {
        Context: context,
        Hierarchy: hierarchy,
        templateId,
      }),
    );

    this.route.queryParams
      .pipe(
        takeUntil(this.unSubscribe),
        map((params) => this.downloaderService.processDownloaderFormsParams(params)),
        switchMap((downloadParams) => this.formService.downloadAsCSV(templateId, downloadParams)),
      )
      .subscribe(
        (res) => {
          this.data = res;
          this.timingMarkerService.finish(timingMarker);
          this.downloaderService.cleanDownloaderLocalStorage();
        },
        (error) => {
          this.data = error;
          this.timingMarkerService.finishWithFailure(timingMarker, error);
        },
      );
  }

  ngOnDestroy(): void {
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }

  getCsvExportContext(queryParams: Params) {
    const prefix = 'Export CSV';
    const autofillText = queryParams?.autofillForms ? 'Autofill' : '';
    const includeAllColumnsText = queryParams?.includeAllColumns ? 'All Columns' : 'Visible Columns';

    const context = [prefix, '-', autofillText, includeAllColumnsText].filter(Boolean).join(' ');

    return context;
  }
}
