import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';

import { ButtonComponent } from '@site-mate/global-web-ui';

import { DataCompressionService } from 'app/shared/service/data-compression/data-compression.service';
import { DownloaderService } from 'app/shared/service/downloader.service';
import { UuidService } from 'app/shared/service/uuid/uuid.service';
import { SharedModule } from 'app/shared/shared.module';

import { DownloaderRoutingModule } from './downloader-routing.module';
import { ExpiredFileComponent } from './expired-file/expired-file.component';
import { DownloadCSVComponent } from './forms/csv/download-csv.component';
import { DownloadFormsComponent } from './forms/download/download-forms.component';
import { ExportFormsComponent } from './forms/export/export-forms.component';
import { DownloadRegisterOnlyComponent } from './forms/register-only/download-register-only.component';
import { DownloadListCSVComponent } from './lists/csv/download-list-csv.component';
import { PercentageBasedDownloaderComponent } from './percentage-based-downloader/percentage-based-downloader.component';
import { DownloadPhotosComponent } from './photos/download/download-photos.component';
import { DownloadPdfPhotosComponent } from './photos/download-pdf/download-pdf-photos.component';
import { QrCodePosterDownloaderComponent } from './qr-code-poster-downloader/qr-code-poster-downloader.component';
import { StateBasedDownloaderComponent } from './state-based-downloader/state-based-downloader.component';
import { DownloadTemplatesCSVComponent } from './templates/csv/download-templates-csv.component';

@NgModule({
  imports: [CommonModule, SharedModule, NgbProgressbarModule, DownloaderRoutingModule, ButtonComponent],
  declarations: [
    PercentageBasedDownloaderComponent,
    DownloadFormsComponent,
    QrCodePosterDownloaderComponent,
    DownloadRegisterOnlyComponent,
    DownloadCSVComponent,
    DownloadPdfPhotosComponent,
    DownloadPhotosComponent,
    ExportFormsComponent,
    StateBasedDownloaderComponent,
    DownloadListCSVComponent,
    DownloadTemplatesCSVComponent,
    ExpiredFileComponent,
  ],
  providers: [DownloaderService, UuidService, DataCompressionService],
})
export class DownloaderModule {}
