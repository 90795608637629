import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'cc-expired-file',
  templateUrl: 'expired-file.component.html',
  styleUrls: ['expired-file.component.scss'],
})
export class ExpiredFileComponent {
  fullPath: string;

  constructor(private readonly router: Router) {}

  returnToDashpivotHome() {
    return this.router.navigate(['/']);
  }
}
