<div
  class="expired-file-container sm-mt-40 sm-flex sm-w-[500px] sm-flex-col sm-gap-y-4 sm-bg-white sm-px-4 sm-py-8"
>
  <img class="sm-self-center" src="/assets/images/expired-file.svg" alt="expired-file" />

  <h2 class="sm-text-center sm-text-xl sm-font-medium" i18n>Link Session Expired</h2>

  <p class="sm-text-center sm-text-sm" i18n>
    The link you are trying to access has either expired or does not exist. Please export this from Dashpivot
    again or request a new link to download the PDF.
  </p>

  <button
    gw-button
    (click)="returnToDashpivotHome()"
    data-test="dashpivot-home-button"
    type="button"
    text="Return to Dashpivot"
    class="sm-mx-auto sm-flex sm-w-auto"
  ></button>
</div>

<footer class="sm-mb-1">
  <p i18n>
    Powered by
    <a href="https://sitemate.com/au/" data-test="sitemate-home-button"> Sitemate </a>
  </p>
</footer>
