<div class="downloader-container">
  <div class="downloader">
    <ng-container [ngSwitch]="data.status">
      <ng-container *ngSwitchDefault>
        <div class="downloader-title">Downloading Files</div>
        <div>
          <p class="default-paragraph downloader-status">
            <i class="cc-icon cc-icon-spinner-lg align-middle"></i>
            {{ data.status | titlecase }}...
          </p>
        </div>
        <div class="downloader-subtitle">This may take some time</div>
      </ng-container>

      <ng-container *ngSwitchCase="downloadProgressStatus.Finished">
        <div class="downloader-title">Download Ready</div>
        <div class="downloader-icon animate-fade-in ready"></div>
        <div class="downloader-subtitle">Thanks for using Dashpivot</div>
        <div class="downloader-button animate-fade-in">
          <a [href]="data.downloadUrl" [download]="data.filename" class="btn btn-primary">Download</a>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="downloadProgressStatus.Error">
        <div class="downloader-title">Download Failed</div>
        <div class="downloader-icon animate-fade-in alert"></div>
        <div class="downloader-subtitle downloader-error-message">
          <p class="default-paragraph text-center">
            Something has gone wrong. Please try again, or contact support if the problem persists.
          </p>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <footer>
    <p>Powered by <a href="https://sitemate.com">Sitemate</a></p>
  </footer>
</div>
