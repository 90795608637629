import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { WINDOW } from 'app/shared/factory/window.factory';
import { DownloaderProgressStatus } from 'app/shared/model/downloader/downloader-progress-status.model';
import { StateBasedDownloaderData } from 'app/shared/model/downloader/state-based-downloader-data.model';
import { ErrorHandler } from 'app/shared/service/error-handler.service';
import { TaskService } from 'app/shared/service/task.service';

@Component({
  selector: 'cc-qr-code-poster-downloader',
  templateUrl: './qr-code-poster-downloader.component.html',
  styleUrls: [
    '../../../styles/components/abstract-downloader.scss',
    './qr-code-poster-downloader.component.scss',
  ],
})
export class QrCodePosterDownloaderComponent implements OnDestroy, OnInit {
  private readonly destroy$ = new Subject<void>();
  state: StateBasedDownloaderData = { status: DownloaderProgressStatus.Rendering };

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly domSanitizer: DomSanitizer,
    private readonly errorHandler: ErrorHandler,
    private readonly taskService: TaskService,
    @Inject(WINDOW) private readonly window: { URL: typeof URL },
  ) {}

  ngOnInit(): void {
    const { taskUrl } = this.activatedRoute.snapshot.queryParams;
    const { teamId } = this.activatedRoute.snapshot.params;
    this.requestQrCodePoster(taskUrl, teamId);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private requestQrCodePoster(taskUrl: string, teamId: string): void {
    this.taskService
      .getQrCodePoster(teamId, taskUrl)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        ({ body, filename }) => {
          // Enforces the ngOnChanges() event
          this.state = {
            status: DownloaderProgressStatus.Finished,
            filename,
            downloadUrl: this.domSanitizer.bypassSecurityTrustUrl(this.window.URL.createObjectURL(body)),
          };
        },
        (error) => {
          // Enforces the ngOnChanges() event
          this.state = { status: DownloaderProgressStatus.Error };
          this.errorHandler.handleForDebug(error);
        },
      );
  }
}
