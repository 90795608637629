import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

import { DashpivotEvent, EventTypes, ExportOutputFormats } from '@site-mate/dashpivot-shared-library';

import { DownloaderProgressStatus } from 'app/shared/model/downloader/downloader-progress-status.model';
import { PercentageBasedDownloaderData } from 'app/shared/model/downloader/percentage-based-downloader-data.model';
import { TimingMarker } from 'app/shared/model/timing-marker.model';
import { DownloaderService } from 'app/shared/service/downloader.service';
import { TimingMarkerService } from 'app/shared/service/timing-marker.service';

import { PhotoService } from '../../../photo/photo.service';

@Component({
  selector: 'cc-download-zip-photos',
  templateUrl: './download-photos.component.html',
})
export class DownloadPhotosComponent implements OnInit, OnDestroy {
  data: PercentageBasedDownloaderData;
  initialStatus = DownloaderProgressStatus.Processing;
  private readonly unSubscribe = new Subject<void>();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly timingMarkerService: TimingMarkerService,
    private readonly photoService: PhotoService,
    private readonly downloaderService: DownloaderService,
  ) {}

  ngOnInit(): void {
    const teamId = this.route.snapshot.paramMap.get('teamId');
    let timingMarker: TimingMarker;

    const { kind } = this.route.snapshot.data;

    this.route.queryParams
      .pipe(
        takeUntil(this.unSubscribe),
        switchMap(({ downloadId }) => {
          const photoIds = JSON.parse(this.downloaderService.getDecompressedLocalStorageItemIds(downloadId));

          const eventType =
            kind === ExportOutputFormats.Original
              ? EventTypes.PhotosDownloadedAsOriginal
              : EventTypes.PhotosDownloadedAsZip;

          timingMarker = this.timingMarkerService.start(
            new DashpivotEvent(eventType, {
              photoIds,
            }),
          );

          return kind === ExportOutputFormats.Original
            ? this.photoService.downloadOriginal(photoIds, teamId)
            : this.photoService.downloadZip(photoIds, teamId);
        }),
      )
      .subscribe(
        (res) => {
          this.data = res;
          this.timingMarkerService.finish(timingMarker);
          this.downloaderService.cleanDownloaderLocalStorage();
        },
        (error) => {
          this.data = error;
          this.timingMarkerService.finishWithFailure(timingMarker, error);
        },
      );
  }

  ngOnDestroy(): void {
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }
}
