<div class="downloader-container">
  <div class="downloader">
    <ng-container [ngSwitch]="status">
      <ng-container *ngSwitchDefault>
        <div class="downloader-title">Downloading Files</div>
        <div>
          <p class="default-paragraph downloader-status">{{ status | titlecase }}...</p>
          <div class="downloader-progress-bar">
            <ngb-progressbar [value]="progress" [max]="100"
              ><small
                [class.half-progress]="progress >= progressValues.half"
                class="progress-value justify-content-center align-items-center d-flex w-100"
                >{{ progress }}%</small
              ></ngb-progressbar
            >
          </div>
        </div>
        <div class="downloader-subtitle">This may take some time</div>
      </ng-container>

      <ng-container *ngSwitchCase="downloadProgressStatus.Finished">
        <div class="downloader-title">Download Ready</div>
        <div class="downloader-icon animate-fade-in ready"></div>
        <div class="downloader-subtitle">Thanks for using Dashpivot</div>
        <div class="downloader-button animate-fade-in">
          <a href="{{ downloadUrl }}" class="btn btn-primary">Download</a>
        </div>
        <!-- add a container for error display -->
        <div aria-label="download error divider" class="sm-h-4"></div>
        <div class="download-warning-form-container" *ngIf="errorForms.length">
          <p class="download-warning-form-header" i18n>## Errors</p>
          <div class="download-warning-form-row" *ngFor="let errorForm of errorForms">
            <em
              class="cc-icon cc-icon-information download-warning-form-icon"
              data-test="download-warning-form-icon"
            ></em>
            <p i18n class="download-warning-form-text" data-test="download-warning-form-message">
              Some photos have not finished uploading and may not appear in the PDF export. Please let the
              uploads finish and try again.
            </p>
            <a
              i18n
              class="sm-col-span-2"
              data-test="error-form-link"
              (click)="gotoForm(errorForm.templateId, errorForm.formId)"
              >View Form</a
            >
          </div>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="downloadProgressStatus.Error">
        <div class="downloader-title">Oops! Something went wrong</div>
        <div class="downloader-icon animate-fade-in error"></div>
        <div class="downloader-subtitle downloader-error-message">
          <p class="default-paragraph">
            The following error ocurred when trying to download.
            <a
              target="_blank"
              href="https://intercom.help/dashpivot/en/articles/4455097-common-problems-with-pdfs"
            >
              Learn more</a
            >
          </p>
          <p [innerHTML]="errorMessage" class="default-paragraph"></p>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="download-expiration-message-container" *ngIf="status === downloadProgressStatus.Finished">
    <p i18n class="download-expiration-message-text">
      This download link is valid until {{ expirationDate }}. If the link expires, you will need to export
      this from Dashpivot again to download.
    </p>
  </div>
  <footer>
    <p>Powered by <a href="https://sitemate.com">Sitemate</a></p>
  </footer>
</div>
