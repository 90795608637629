import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, map, switchMap, takeUntil, tap } from 'rxjs';

import { PercentageBasedDownloaderData } from 'app/shared/model/downloader/percentage-based-downloader-data.model';
import { DownloaderService } from 'app/shared/service/downloader.service';

import { ListsService, IListDownloadParams } from '../../../lists/lists.service';

@Component({
  selector: 'cc-download-list-csv',
  templateUrl: './download-list-csv.component.html',
  styleUrls: ['./download-list-csv.component.scss'],
})
export class DownloadListCSVComponent {
  data: PercentageBasedDownloaderData;

  private unSubscribe = new Subject<void>();
  constructor(
    private readonly route: ActivatedRoute,
    private readonly downloaderService: DownloaderService,
    private readonly listsService: ListsService,
  ) {}

  ngOnInit(): void {
    const listId = this.route.snapshot.paramMap.get('listId');

    this.route.queryParams
      .pipe(
        takeUntil(this.unSubscribe),
        map((params) => this.downloaderService.processDownloaderItemsParams(params)),
        tap((params) => {
          if (params.ids) {
            params.ids = JSON.parse(params.ids);
          }
        }),
        switchMap((downloadParams) =>
          this.listsService.downloadAsCSV(listId, downloadParams as IListDownloadParams),
        ),
      )
      .subscribe({
        next: (data) => {
          this.data = data;
          this.downloaderService.cleanDownloaderLocalStorage();
        },
        error: (error) => {
          this.data = error;
        },
      });
  }

  ngOnDestroy(): void {
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }
}
